// Pages & Components
import { Main } from "../../_index";

export default function Home() {
  return (
    <>
      <Main />
    </>
  );
}
